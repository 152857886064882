*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none!important;
}
ul li{
    list-style-type: none;
}
ol, ul{
    padding-left: 0!important;
}
.container{
    max-width: 1080px!important;
}
:root{
    --primary:rgb(243, 112, 2);

    --rgba-primary-1:rgba(243,112,2,0.1);
    --secondary:#000;
    --white:#fff;
    --border-color:#e0e0e0;
    --text-dark-light:#515151;
    --radius-xl:50px;
    --radius:10px;
    --text-light:#a2a2a2;
    --bg-success:#19ad6f;
    --bg-light:#f4f6f9;
    --text-black:#202020;
    --text-warning:#fcaf17;
    --radius-sm:8px;
    --blue_dark :#12344D;
    --font-primary: 'Poppins', sans-serif;
 
    --box-shadow-default: 0 1px 8px 0 rgba(0, 0, 0, 0.1);

}
h1,h2,h3,h4,h5,h6{
    font-weight: 500;
}
h1{
   
}
h6{
    font-size: 18px;
}
hr{
    border-color: #c2c2c2!important;
    margin: 12px 0 !important;
}
.rounded{
    border-radius: var(--radius)!important;
}
.btn-primary{
    background-color: var(--primary)!important;
    border-color: var(--primary)!important;
   
    height: 51px;
    border-radius: 7px;
   
    width: 100%;
   
    font-size: 14px!important;
    font-weight: 600;
}
.iq-bg-fixed{
    position: relative;
}
.iq-bg-fixed:after{
    content:"";
    position: absolute;
    background-color: #01a3ff;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    opacity: 0.6;
}

.ticket {
    width: 100%;
    height: 260px;
    background: #f5f5f5;
    color: #000;
    
   
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .badge-light-warning{
    padding:10px;
  }

  .booking_separetor{
    position: absolute;
    bottom: 21%;
    width: calc(100% - 40px); /* Adjust width to prevent overflow */
    height: 0; /* Remove height to only show the border */
    border-bottom: 1px dashed #f26b1d; /* Dashed border */
    z-index: 0;
    left: 20px;
  }
  
  /* Create the notches */
  .ticket::before, .ticket::after {
    content: "";
    width: 40px;
    height: 40px;
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
  }
  
  .ticket::before {
    top: 50%;
    left: -20px;
    transform: translateY(132%);
  }
.main-booking-box{
    display:flex;
    align-items: center;
    justify-content: space-between;
}
  .booking-light-warning {
    padding: 10px !important;
    background: rgb(243 110 32 / 10%);
    color: #f26d1f !important;
    font-weight: 500 !important;
    margin-top: 16px;
}
.booking-points{
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
   
}
.booking-points small{
    font-size: 11px;
    color: rgb(142, 142, 142);
}
.booking-points h6{
    font-size: 14px !important;
    margin-bottom: 0;
    color: #8e8e8e;
}
  
  .ticket::after {
    top: 50%;
    right: -20px;
    transform: translateY(132%);
  }
  
  .ticket-content {
    padding: 20px;
  }
  
  .ticket h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .ticket p {
    margin: 5px 0;
    font-size: 14px;
  }
  

.btn-primary:hover{
    background-color: var(--primary)!important;
    border-color: var(--primary)!important;
}
.itinerary-days:last-child::after {
    content: none;
}
.basic-details-item{
    display: flex
;
    align-items: center;
}
.basic-details-item__event-name {
    font-weight: 500;
    line-height: 22px;
    color: #202020;
    font-size: 15px;
    text-transform: capitalize;
}
.basic-details-item__description .flag {
    padding: 2px 5px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    background: #0fb680;
    border-radius: 2px;
}
.amazing-experiences {
    margin-bottom: 15px!important;
    font-weight: 500!important;
    font-size: 14px!important;
    line-height: 22px;
    color: #202020;
}
.basic-details-item__event-type {
    display: flex
;
    gap: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #515151;
    text-transform: capitalize;
    align-items: center;
}
 .basic-details-item__event-name {
    font-weight: 500;
    line-height: 22px;
    color: #202020;
    font-size: 15px;
    text-transform: capitalize;
}
.from-control-lg{
    display: flex
    ;
        width: 100%;
        height: 51px;
        border: 1px solid rgb(224, 224, 224);
        border-radius: 10px;
        padding-left: 20px;
    
}
.from-control-lg::placeholder{
    font-size: 14px;
}
.title{
    font-size: 16px;
    color: var(--text-dark-light);
}

.title-sm{
    font-size: 12px;
    font-weight: 400;
    color: var(--text-light);
    line-height: 18px;

}
.coustom_tabs{
    border: 0!important;
    padding: 6px 0;
}
.coustom_tabs .nav-link.active{
    border: 0!important;
    color: var(--primary);
}
.coustom_tabs .nav-link.active svg path{
    fill: var(--primary);
}
.coustom_tabs .nav-item{
    position: relative;
    z-index: 6;
}
input[type="checkbox"]:checked + .form-check-label {
    background-color: #4caf50; /* Change the label's background when the checkbox is checked */
    color: #fff; /* Optional: change text color */
  }
.coustom_tabs .nav-link{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-color:  transparent;
    position: relative;
   
        
        color: #515151;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
     
        text-transform: capitalize;
        
        transition: all .1s ease-in;
        min-height:60px;
       

        white-space:nowrap;
        text-overflow:ellipsis;

    
}
.city_name{
    margin-top:7px;
}
.HomePageBody_verticalSeperator__e_C7b{
    width: 1px;
    height: 46px;
    background: #e0e0e0;
    display: flex
;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-left: 28px;
    margin-right: 24px;

}
.coustom_tabs .nav-link svg,
.coustom_tabs .nav-link img{
    margin-top: 10px;
}
.trade_name svg{
    margin-top: 0!important;
}
.heading{
    font-size: 22px;
    font-weight: 600;
    color: #202020;
    text-transform: capitalize;
    margin-bottom: 0;

}
.fs-16{
    font-size: 1.5rem!important;
}
.viewAll{
    color: var(--primary);
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;

}
.review_area .title-xs{
    color: rgb(122, 122, 122);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    display: flex;
    align-items: center;

}
.fs-9{
    font-size: 9px;
}
.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.font-w600{
    font-weight: 600;
}
.review_area .title-xs svg{
    width: 12px;
    height: 12px;
}
.review_area .title-xs b{
    display: flex;
    align-items: center;
    color: #19ad6f;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
    margin: 0 5px;

}

.text-success{
    color: var(--bg-success);
}

.badge-light{
    display: flex
    ;
        width: 100%;
        background: linear-gradient(rgba(255, 186, 10, 0.1), rgba(255, 186, 10, 0));
        position: relative;
        border-radius: 5px;
        margin-bottom: 2px;
         padding: 5px 10px;
         margin-bottom: 20px;
    
    
}
.profile_dropdown.dropdown-toggle::after{
content: none;
}
.fs-18{
    font-size: 18px!important;
}
.profile_dropdown_main .dropdown-menu{
    z-index: 20;
    padding-top: 10px !important;
    padding-left: 0;
    transition: all 0s ease-out;
    background: #fff;
    box-shadow: 0px 0px 20px 5px rgba(216, 216, 216, 0.25);
    border: 1px solid #f2f2f2 !important;
    width: 200px;
    height: 140px;
    border-radius: 10px !important;
  
}
.profile_dropdown_main .dropdown-item{
    width: 100%;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 !important;
    white-space: pre;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    color: #757575;
    line-height: 0;
    height: 27px;
}
.profile_dropdown_main .dropdown-item:hover{
    background-color: white;
}
.badge-success{
        display: flex;
        align-items: center;
        padding: 5px 2px;
        color:var(--bg-success)!important;
        font-size: 9px;
        font-weight: 600;
        line-height: 14px;
        text-transform: capitalize;
        background: linear-gradient(90deg, rgba(11, 130, 42, 0.11) 3.64%, rgba(11, 130, 42, 0.1));
        gap: 3px;
        margin-left: -0.5px;
        margin-right: -0.5px;
        border-radius: 0!important;
    
}
.trip-card-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.phone_icon{
    display: flex
    ;
        align-items: center;
        justify-content: center;
        height: 51px;
        border-radius: 7px;
        border: 1px solid var(--primary);
        background: rgb(255, 255, 255);
        color: var(--primary);
        font-size: 14px;
        font-weight: 600;
        min-width: 51px;
        margin-right: 8px;
    
}
.phone_icon svg{
    width: 14px;
    height: 14px;
}
.text-orange{
    color: var(--primary)!important;
}
.bottom-content .title{
   
        color: rgb(32, 32, 32);
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin-right: 5px;
    
}
del{
    color: rgb(81, 81, 81);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0px 5px;
    
}
.dubai_content h6{
    margin-bottom: 40px;
    line-height: 23px;
    color: #202020;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
}
.dubai-media img{
    border-radius:var(--radius);
    height:340px;
    object-fit: cover;
    object-position: center;

}
.dubai-media:hover .swiper-button-prev,
.dubai-media:hover .swiper-button-next {
    opacity: 1;
}
.seprator{
    width: 98vw;
    height: 1px;
    background-color: var(--border-color);
    /* padding-left: 72px; */
    position: relative;
    left: -327px;
}

.seprator-gradend{
    display: flex
    ;
        align-items: center;
        margin: 50px 0;
        min-height: 1px;
        min-width: 1080px;
        background: linear-gradient(270deg, hsla(0, 0%, 100%, .05) -.13%, hsla(0, 0%, 88%, .05) 0, #e0e0e0 49.17%, hsla(0, 0%, 88%, .05));
    
}
.swiper-button-prev:after, .swiper-button-next:after{
    font-size: 9px!important;
    color: var(--secondary);
    font-weight: 700;
 
}
.swiper-button-prev, .swiper-button-next{
    background-color: var(--white);
    width: 30px!important;
    max-width: 30px;
    height: 30px!important;
    background-color: var(--white);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.5s;
}
.swiper-pagination-bullet-active{
    background-color: var(--white)!important;
}
.swiper-pagination-bullet{
    background-color: rgba(255, 255, 255, 0.8)!important;
}
.trip-media{
    position: relative;
}
.sale-tag{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: linear-gradient(93deg, #ff7b26, #ec048c);
    margin-top: 15px;
    height: 22px;
    align-items: center;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 0 8px;

}
.sale-tag img{
    width: 18%!important;
}
.sale-tag small{
    white-space: nowrap;
    margin-left: 8px;
}
.border-right{
    border-right: 1px solid var(--border-color);
}
.bg-light{
    background-color: var(--bg-light);
}
.section-title{
    color: #515151;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: capitalize;
    margin-right: 10px;

}
.section-span{
    color: #fcaf17;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.5px;
    text-transform: capitalize;

}
.banner-content{
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-height: 700px;

}
.about_title{
    color: #8e8e8e;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: .51px;
    text-transform: uppercase;

}
.contentText{
    color: #515151;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-top: 10px;

}
.placeholder_team {
    width: 160px;
    height: 160px;
    flex-shrink: 0;
    border-radius: 50%;
    border: 1px solid #f47625;
    background: linear-gradient(135deg, #f47625, #ffba0a);
    display: flex;
    justify-content: center;
    align-items: center;
}
.placeholder_team h6{
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 103px;

}

.itinerary{
    margin-top: 20px;
    border-radius: 15px;
    border: 1px solid #e7e7e7;
    padding: 18px 18px 38px 18px;
    background-color: #fef9f4;
    font-family: var(--font-family);

}

.itinerary-heading h1{
    font-size: 28px !important;
    color: #000 !important;
    font-weight: 700 !important;
    line-height: 1.5 !important;
    text-transform: capitalize;

}
.itinerary-days .accordion{
    border-bottom: 1px solid #ff4500;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.itinerary-days .accordion-button{
    color: #ff4500!important;
    padding: 0;
}
.itinerary-days .accordion-item{
    background-color: transparent!important;
    border: 0;
}
.itinerary-days .accordion-body{
padding-left: 0;
}
.team_content .btn-about-white{
    width: 160px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #f47625;
    background: #fff;
    margin-top: 10px;
    color: #f47625;
    text-align: center;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Divider_divider__yaBCO  {
    display: flex
;
    align-items: center;
    margin: 50px 0;
    min-height: 1px;
    min-width: 1080px;
    background: linear-gradient(270deg, hsla(0, 0%, 100%, .05) -.13%, hsla(0, 0%, 88%, .05) 0, #e0e0e0 49.17%, hsla(0, 0%, 88%, .05));
}
.hiring-titk h1{
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;

}
.hiring-titk p{
    color: #515151;
    text-align: center;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv {
    background: #f4f6f9;
    padding-top: 50px;
    padding-bottom: 50px;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 .ThrillophiliaExpeditionSection_expedetionTitle__cs_DK {
    color: #202020;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.MultiColorHeading_multiColorHeadingContainer__QjlxL .MultiColorHeading_firstText__xf0Wa {
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
}
.MultiColorHeading_multiColorHeadingContainer__QjlxL .MultiColorHeading_secondText__6QtSP {
    color: #fcaf17;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.MultiColorHeading_subheading__Gk1ie {
    color: #515151;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 {
    width: 100%;
    position: relative;
    display: flex
;
    gap: 180px;
    justify-content: center;
    min-height: 400px;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_progressMeterContainer__CGD06 {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex
;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 .ThrillophiliaExpeditionSection_imageSectionContainer__2KT_N {
    display: flex
;
    flex: 1 1;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 .ThrillophiliaExpeditionSection_imagePoster__Mm10g {
    width: 450px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    display: flex
;
    position: relative;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 .ThrillophiliaExpeditionSection_contentSectionContainer__A9al2 {
    display: flex
;
    flex: 1 1;
    flex-direction: column;
    gap: 20px;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 .ThrillophiliaExpeditionSection_expedetionDescription__w4mWH {
    color: #202020;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_firstTitleContent__n3WKk {
    display: flex
;
    flex-direction: column;
}
.MultiColorHeading_multiColorHeadingContainer__QjlxL {
    display: flex
;
    flex-wrap: wrap;
    justify-content: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.tour_content_left .style-1 h1{
    font-size: 22px;
    line-height: 33px;
}
.itinerary_box{
    background-color:var(--rgba-primary-1);
    padding: 1rem;
}
.tag{
    background-color: var(--primary);
    color: #fff;
    padding: 10px;
}
.tag h6{
    margin-bottom: 0;
    font-size:16px;
    font-weight: 600;
    padding-left: 8px;
}
.service-page-accordion .panel-title a {
    font-size: 18px;
    color: var(--primary);
    margin: 0;
    padding: 0px;
}
.itinerary-days {
    position: relative;
    padding-left: 25px;
    margin-top: 30px;
}
.itinerary-days::before {
    content: "";
    position: absolute;
    left: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f79734;
    top: 3px;
}
.service-page-accordion {
    border-bottom: 1px solid var(--primary);
    margin-bottom: 14px;
}
.itinerary-days::after {
    content: "";
    position: absolute;
    left: 0px;
    width: 2px;
    height: calc(100% + 23px);
    background-color: #f79734;
    top: 13px;
}
.service-page-accordion .panel-title .fa-angle-down:before, .service-page-accordion .panel-title .fa-angle-up:before {
    color: var(--primary);
}
.service-page-accordion .panel-title a {
    font-size: 18px;
    color: var(--primary);
    margin: 0;
    padding: 0px;
}
.MultiColorHeading_multiColorHeadingContainer__QjlxL {
    display: flex
;
    flex-wrap: wrap;
    justify-content: center;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_firstTitleContent__n3WKk .ThrillophiliaExpeditionSection_expedetionContentTitle__67FXW {
    font-size: 22px;
}
.MultiColorHeading_multiColorHeadingContainer__QjlxL .MultiColorHeading_firstText__xf0Wa {
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_progressMeterContainer__CGD06 {
    transform: translateY(130px);
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_normalNumber__yR8ck {
    color: #cbcbcb;
}

.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_yearNumber___c4pg {
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: all .3s ease;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_normalDot__AblcJ {
    background: #d9d9d9;
}

.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_bigDotContainer__Rk4EJ {
    width: 30px;
    height: 30px;
    transition: all .3s ease;
    border-radius: 50%;
    position: relative;
    display: flex
;
    justify-content: center;
    align-items: center;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_dottedLine__xfbwX {
    display: flex
;
    flex: 1 1;
    margin-top: 10px;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    position: relative;
    min-width: 3px;
    align-items: center;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_dotItems__CBVI_ {
    width: 1px;
    height: 5px;
    background-color: #cbcbcb;
}
.ThrillophiliaExpeditionSection_heading__oVZF6{
    margin-bottom: 40px;
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_rippleContainer__AZ54W {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    transition: all .3s ease;
    transform: scale(1.3);
    animation: ThrillophiliaExpeditionSection_ripple__R8t3_ 1.1s ease-in-out;
}

@keyframes ThrillophiliaExpeditionSection_ripple__R8t3_ {
    0% {
        border: 1px solid #f47625;
        opacity: 1;
        transform: scale(1.1)
    }

    to {
        border: 1px solid #f47625;
        opacity: 0;
        transform: scale(1.8)
    }
}
.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_verticalBar__IsiZQ {
    position: absolute;
    width: 2px;
    background-color: #f37022;
}

.ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_progressMeterContainer__CGD06 {
position: absolute;
top: 0;
bottom: 0;
display: flex
;
align-items: center;
flex-direction: column;
overflow: hidden;
}
.gradend-light{
    background: linear-gradient(92deg,#f37022 1.79%,#fcaf17 97.06%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about_heding{
    font-size: 65px!important;
    font-weight: 700!important;
    color: #202020!important;
}
.about_para{
    color: #202020;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: .48px;
    text-transform: capitalize;

}
.lotti-box{
    transform: scale(1.8);
}
.sub-section-title{
   
    font-family: var(--font-primary);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
    color: var(--text-black);

}
.fw-500{
    color: #515151;
    font-family: var(--font-primary);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 5px;

}
.section-padding{
    padding: 50px 0;
}

.team-media img{
    width: 150px;
    height: 150px;
    border-radius: 50%;

}
.team{
    text-align: center;
   
}
.team_content h6{
    color: #515151;
    text-align: center;
    font-family:var(--font-primary);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
   

}
.team_content small{
    font-size: 13px;
    font-weight: 400;
}
.founder-box{
    display: grid;
    grid-template-columns:repeat(5,1fr);
    gap: 70px;
}
.team_content p{
    margin-bottom: 0;
}
.join_team{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 1176px;
    padding: 40px;
}
.join_team h2{
    color: #202020;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 8px;
}
.join_team p{
    color: #515151;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 20px;
}
.btn-shine{
    display: flex;
    border-radius: 7px;
    background: linear-gradient(91deg, #ffba0a, #f37022);
    box-shadow: 0 0 10px 0 rgba(243, 112, 34, .3);
    padding: 15px 30px;
    color: #fff;
    border: 0;
}
.city_box{
    text-align: center;
}
.section-h h2{
    color: #202020;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;

}
.city_content h2{
    color: #202020;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 5px;


}
.city_content p{
    color: #515151;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.text-warning{
    color: var(--text-warning);
}
.takeoff_content ul li{
    list-style-type: disc;
    color: var(--text-black);
    font-family: var(--font-primary);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;

}
.coustom_about_title{
    color: #fcaf17;
    font-family: var(--font-primary);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.thumb_content{
    text-align: center;
}
.thumb_content h2{
    color: #202020;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;

}
.thumb_content{
    margin-top: 20px;
    

}
.thumb_content p{
    color: #515151;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 15px;
    margin-bottom: 27px;
    text-align: center;
}
.spot_card .card-header{
    display: flex
;
    flex-direction: row;
    margin: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
    height: 50px;
    align-items: center;
    background-color: transparent;

}
.gallary_box img{
    height: 170px;
    object-fit: cover;
}
.life_box{
    text-align: strat;
    display: flex;
    align-items: center;   
    justify-content: center; 
    padding: 30px;
}
.life_box img{
    width: 10%;
}
.life_box h2{
    color: var(--secondary);
    font-family: var(--font-primary);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0;

}
.life_box a{
    color: #8e8e8e;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    

}
.main_life_box{
    background-color: var(--white);
    box-shadow: 0 0 14px 0 rgba(0,0,0,.1);
    position: relative;
}
.main_life_box:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 6px solid transparent; /* Transparent border for spacing */
    background: linear-gradient(90deg, #f47625, #fcaf17);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    border-radius: var(--radius);
}
.life_media{
    position: relative;
}
.copy_link{
    position: absolute;
    top: 15px;
    right: 15px;
}
.life_total{
    padding-bottom: 30px;
    position: relative;
}
.life_total :after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 149px;
    height: 270px;
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .61) 36.84%, hsla(0, 0%, 100%, .84) 77.11%, #fff 98.95%);
    
}
button:focus:not(:focus-visible){
    border-color: transparent!important;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border-color: transparent!important;
}
.trade_name{
    position: absolute;
    top: 0px;
    right: -10px;
    display: flex;
    width: 43px;
    height: 13px;
    flex-shrink: 0;
    border-radius: 2px;
    background: linear-gradient(95deg, #fcaf17 0, var(--primary, #f37002) 100%);
    justify-content: center;
    align-items: center;

}
.trade_name span{
    color: #fff;
font-size: 7px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.activites_tab{
    /* border: 1px solid var(--border-color);
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: var(--radius);
    padding: 5px; */
   
}
.activites_tab .nav-tabs{
    
    padding: 2px!important;
    height: 46px;
    border-radius: 7px;
    border: 1px solid #cbcbcb;
    background: #fff;
    display: flex;
    align-items: center;
    max-width: max-content;
    gap: 10px;
    position: relative;
}
.main-bar{
    padding-top: 5.5px;
    padding-bottom: 6px;
    /* position: sticky;
    top: 0;
    z-index: 2; */
}
.title-small b{
    color: #000;
}
.title-small span{
    display: flex
    ;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: #515151;
        width: max-content;
    
}

/* .activites_tab .nav-tabs .nav-link{
    display: flex
;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 7px;
    width: 100px;
    color: #000;
    z-index: 2;
    transition: all .3s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
} */

.activites_tab .nav-tabs .active{
   
    background-color: var(--primary) !important;
    color: var(--white)!important;
   

}
.activites_tab .nav-tabs .nav-link{
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 7px;
    width: 100px;
    color: #000;
    z-index: 2;
    transition: all .3s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    
}
.media-box.style-1{
    margin-top: 40px;
}
.load_more{
    margin: 0 auto;
    text-align: center;
   
}
.load_more .btn-outline-primary{
    width: max-content;
    min-width: 300px;
    padding: 10px;
    border: 1px solid var(--primary);
    color: var(--primary);
    background: transparent;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(244, 118, 37, .3);
    transition: all .2s ease-in-out;
    text-align: center;
    margin-top: 40px;

}
.load_more .btn-outline-primary:hover{
    background: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);

}
.activites_card{
    display: flex;
    border: 1px solid var(--border-color);
    padding: 20px;
    border-radius: var(--radius);
    box-shadow: 0 0 7px 0 rgba(0,0,0,.1);
    margin-bottom: 40px;
}
.activites_media img{
    border-radius: var(--radius-sm);
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-right: 16px;
}
.activies_content h1{
        color: #202020;
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
    
}
.badge-dark-light{
    background: #f4f4f4;
    color: #8e8e8e;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    padding: 3px 6px;
    border-radius: 3px;
    width: max-content;

}
.activies_content{
    overflow: hidden;
}

.activies_content ul{
   display: flex;
   align-items: center;
}
.activies_content ul li{
    margin-right: 5px;
}
.activies_content ul li:last-child{
    margin-right: 0;
}
.arrival-para{
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
   
}
.arrival-para p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #515151;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

}
.review_area{
    padding: 10px;
    border: 1px solid var(--border-color);
    width: 60%;
    border-radius: var(--radius-sm);
    transition: all 0.5s;
    cursor: pointer;
}
.review_area:hover{
    box-shadow: 0 0 7px 0 rgba(0,0,0,.1);
}
.review_media img{
    width: 50px;
    height: 50px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 16px;
}
.review_content{
    color: #202020;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    text-transform: capitalize;
    margin-top: 4px;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}
.btn-outline-primary{
    border-radius: 7px!important;
    width: 109px;
    height: 41px;
    font-weight: 600;
    font-size: 12px!important;
    border: 1px solid var(--primary)!important;
  background-color: transparent!important;

   color: var(--primary)!important;
    font-size: 14px;
    font-weight: 500;

}
.review_content small{
    color: #8e8e8e;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: .3px;
    text-transform: capitalize;
}
.review_content h6{
    color: #202020;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    text-transform: capitalize;
    margin-top: 4px;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.arrival-para{
    width: 90%;
}
.read_more{
    font-size: 14px;
    color: #515151;
    cursor: pointer;
}
.modal-title{
    font-size: 20px!important;
    margin-bottom: 0!important;

}
.fs-14{
    font-size: 14px!important;
}

.modal-header .btn-close{
    opacity: 1;
    width: 20px;
    height: 20px;
}
.title-xs{
        color: #7a7a7a;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
}
.circle-svg{
    background: #515151;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 8px;

}
.title-small{
    align-items: center;
    display: flex;
    font-size: 12px;
}
.title-small b{
    font-size: 12px;
}
.tour_content_left{
    margin-top: 30px;
}
.tour_content_left h1{
font-size: 30px;
font-weight: 600;
line-height: 45px;
color: #000;

}
.badge_days{
    background-color: #bf500e;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    width: auto;
    height: 27px;
    border-radius: 20px;
    background: #bf500e;
    margin-top: 9px;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}
.number{
    font-size: 31px;
    font-weight: 700;
    line-height: 47px;
    color: #cbcbcb;

}
.facilites_box span{
    font-size: 14px;
    color: #515151;
}
.facilites_box{
    margin-top: 30px;
    border-top: 1px solid var(--border-color);
    padding-top: 25px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 25px;
    margin-bottom: 30px;
}
.choose_trip h6{
    margin-bottom: 20px;
}
.choose_media{
    position: relative;
}
.choose_media img{
    width: 100px;
    height: 100px;
    border-radius: 6px;
    object-fit: cover;

}
.choose_media:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 35px;
    left: 0;
    bottom: 0;
    width: 100px;
    z-index: 2;
    border-radius: 10px;
    background: linear-gradient(180deg, hsla(0, 0%, 43%, 0), rgba(0, 0, 0, .31) 29.51%, rgba(0, 0, 0, .6) 64.58%, rgba(0, 0, 0, .71));
}

.choose_content small{
    color: #8e8e8e;
    font-size: 10px;
    font-weight: 400;
    word-wrap: break-word;
    line-height: 15px;
}
.choose_content h6{
    color: #202020;
    font-size: 12px;
    font-weight: 700;
    word-wrap: break-word;
    line-height: 18px;
}
.tour_days{
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 15px;
    line-height: 23px;
    font-weight: 700;
    position: absolute;
    bottom: 5px;
    left: 7px;
    z-index: 3;
}
.grid-view{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.grid-box{
    margin-right: 16px;
}
.distance{
    position: relative;
}
.distance label{
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 7px;
    border: 1px solid #e0e0e0;
    transition: all .2s ease-in-out;
    width: 100%;
    color: #f37002;

}
.distance .form-check-input{
    position: absolute;
    top: -4px;
    right: 0;
    border-radius: 0 6px 0 8px !important;

}
.form-check-input:checked{
    background-color: var(--primary)!important;
    border-color: var(--primary)!important;
}

.form-check-input:checked + .form-check-label span {
    color: #f74b00!important; /* Desired label color when checked */
  }

.choose_trip{
    margin-bottom: 20px;
}  
.form-check-label{
    position: relative;
}
.cate{
    display: flex;
    align-items: center;
}
.cate label{
    position: relative;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin-right: 16px;
    cursor: pointer;
    text-transform: capitalize;
    min-width: -moz-fit-content;
    min-width: fit-content;
    color: #515151;
    transition: all .2s ease-in-out;

}
.cate label .form-check{
    margin-bottom: 0;
}
.cate .form-check-input{
    position: absolute;
    top: -3px;
    right: 0;
    border-radius: 0 4px 0 4px!important;

}
.choose_trip ul{
    padding-left: 20px!important;
}
.choose_trip ul li{
    list-style-type: disc;
    margin-bottom: 10px;

}
.choose_tabs .nav-tabs{
     margin: 0 auto;
    max-width: 1080px;
    display: flex;
    background: #f4f6f9;
    gap: 15px;
    width: 100%;
    border-radius: 7px;
    padding: 7px 10px;
    overflow-x: scroll;
    scrollbar-width: none;
    border-bottom: 0;
    padding-left: 10px!important;
}
.choose_tabs .nav-tabs .nav-link{
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #8e8e8e;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .07);
    background: #fff;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    line-height: 21px;
    gap: 5px;
    min-width: -moz-fit-content;
    min-width: fit-content;
   
    
}
.choose_tabs .nav-tabs .nav-link.active{
    background-color: var(--primary)!important;
    color: #fff;
}
.tour_image_1{
    height: 300px;
    object-fit: cover;

}
.tour_according{
    margin-top: 20px;
}

.tour_according .accordion-button{
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    padding: 15px 15px 15px;
    border-radius: 7px; 
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

}
.accordion-button:not(.collapsed){
    background-color: transparent!important;
    box-shadow: none!important;
}
.tour_according span{
    font-size: 14px ;
    line-height: 1.7;
}
.under_line{
    position: relative;
    font-size: 12px!important;

}
.under_line:after{
    content: "";
    background-image:url('data:image/svg+xml,<svg width="90" height="9" viewBox="0 0 42 9" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"><path d="M38.3068 3.73965C39.1688 3.54422 39.8527 3.48777 40.8634 3.16324C40.9529 3.1346 41.1136 3.10058 41.0731 3.15032C40.3657 4.00338 39.5417 4.85074 38.8143 5.70808C37.9137 6.77064 36.4158 6.67523 36.0349 6.53628C35.9805 6.51628 36.0191 6.47681 36.1507 6.41787L36.2659 6.3663C36.3226 6.34092 36.3675 6.31208 36.4006 6.2798C37.349 5.3504 37.9386 4.53191 38.1695 3.82433C38.1718 3.81786 38.1778 3.81005 38.1868 3.80161C38.1959 3.79317 38.2077 3.78436 38.2214 3.77598C38.235 3.76759 38.2499 3.75988 38.2648 3.75354C38.2797 3.7472 38.2942 3.74243 38.3068 3.73965Z" fill="%23F2AE28"></path><path d="M0.443093 6.50555C1.14573 4.98154 1.69593 4.60737 2.74423 4.43088C13.5439 2.60295 24.3394 1.66582 35.1308 1.61949C37.5272 1.60932 39.4672 1.4088 41.0409 2.82396C41.0568 2.83786 41.0696 2.86121 41.0771 2.89044C41.0846 2.91966 41.0865 2.95313 41.0825 2.98572C41.0786 3.01831 41.0689 3.0482 41.055 3.07081C41.0412 3.09342 41.0239 3.10749 41.0058 3.11087C40.173 3.10523 38.8516 3.22572 38.0858 3.46891C32.3019 3.55836 26.4811 3.59949 20.6903 4.04851C14.844 4.50059 8.99851 5.19445 3.15392 6.13009C2.21876 6.2795 1.34085 6.49094 0.52019 6.76442C0.36606 6.81442 0.340361 6.72813 0.443093 6.50555Z" fill="%23F2AE28"></path></svg>');
    position: absolute;
    width: 100% ;
    height: 50px;
    top: 17px;
    background-size: 100%;
    left: 0%;
    background-position: top;
    background-repeat: no-repeat;
}
.th-transfer-stops-main-wrapper .th-transfer-stop-dotted-line {
    border-left: .5px dotted #8e8e8e;
    z-index: 1;
    margin-left: 8px;
    margin-top: 18px;
    margin-bottom: 18px;
}
.th-transfer-stops-main-wrapper .th-transfer-all-stops-container {
    width: 100%;
}
.th-transfer-stops-main-wrapper .th-transfer-stop-container .th-transfer-stop-indicator {
    border-radius: 5px;
    background: linear-gradient(90deg, rgba(252, 175, 23, .2), hsla(0, 0%, 87.8%, 0));
    width: 76px;
    position: relative;
    bottom: -4px;
    padding-left: 5px;
    padding-bottom: 4px;
    padding-top: 2px;
    color: #e9a010;
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
    border-bottom-left-radius: 0;
}
.th-transfer-stops-main-wrapper .th-transfer-stop-wrapper {
    display: flex
;
    flex-direction: column;
    padding: 12px;
    gap: 10px;
    border-radius: 7px;
    border: .5px solid #e0e0e0;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .08);
    position: relative;
    width: 100%;
    min-width: 632px;
}
.th-transfer-stops-main-wrapper .th-transfer-stop-wrapper .th-transfer-stop-title {
    display: flex
;
    gap: 10px;
    flex-direction: row;
    align-items: center;
}
.th-transfer-stops-main-wrapper .th-transfer-stop-spacing {
    height: 10px;
}

.th-transfer-stops-main-wrapper .th-transfer-stop-wrapper .th-transfer-stop-point-duration, .th-transfer-stops-main-wrapper .th-transfer-stop-wrapper .th-transfer-stop-point-title {
    color: #202020;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 400;
    text-transform: capitalize;
    word-wrap: break-word;
    line-height: 21px;
    text-align: left;
}
.th-transfer-all-stops-container{
    display: flex;
    align-items: center;
}
.loaction_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
}
.dashed_border{
    border-left: 5px dotted #8e8e8e;
    z-index: 1;
    margin-left: 8px;
    margin-top: 40.5px;
    margin-bottom: 34.5px;
}
.th-transfer-stop-main-wrapper{
    width: 100%;
    min-width: 638p
}
.th-stay-timeline {
    display: flex
;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 8px 10px 5px;
    width: auto;
    height: 50px;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-top: 10px;
}
.th-stay-timeline {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 8px 10px 5px;
    width: auto;
    height: 50px;
    background-color: #f4f4f4;
    border-radius: 5px;
}
.th-stay-timeline .section--top {
    align-items: center;
    font-size: 10px;
    line-height: 15px;
    color: #515151;
    font-weight: 500;
    display: flex
    ;
        justify-content: space-between;
    
}
.th-stay-timeline .section--top .separator {
    margin: 0 10px;
    flex-grow: 1;
    position: relative;
    height: 1px;
    border-bottom: 1px dashed #8e8e8e;
}
.th-stay-timeline .section--top .duration-summary {
    display: flex
;
    align-items: center;
    gap: 3px;
    padding: 0 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f4f4f4;
    font-size: 12px;
    line-height: 18px;
}
.th-stay-timeline .section--bottom {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #515151;
    display: flex;
    justify-content: space-between;
    
}
.hotel-options-heading {
    color: #1f7996;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 15px;
    margin-top: 16px;
}
.smilmiar_content{
  
    line-height: 21px;
    font-size: 14px;
    color: #202020;
    font-weight: 500;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

}
.rounded-lg{
    border-radius: 10px!important;
}
.similar_media{
    position: relative;
    overflow: hidden;
}
.similar_review{
    position: absolute;
    bottom: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    z-index: 1;
}
.similar_review span{
    color: #fff;
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
}
.similar_media:after{
    position: absolute;
    content: "";
    height: 100%;
    display: block;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 30%;
    background: linear-gradient(0deg, rgba(0, 0, 0, .9), transparent);
    pointer-events: none;
    margin-bottom: -2px;
    z-index: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius:15px;

}
.box-Inclusions{
    padding: 15px;
    background: rgba(41, 179, 223, .1);
    border-radius: 5px;

}
.pakage_box{
    display: flex
    ;
        flex-direction: column;
        border-radius: 10px;
        border: 1px solid #e0e0e0;
        width: 100%;
        padding: 20px;
        margin-top: 30px;
    

}
.pakage_box h4{
    display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #e0e0e0;
        color: #202020;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        margin-bottom: 20px;
}
.package-list{
    border-right:1px solid var(--border-color)
}
.package-list h6{
    display: flex;
    align-items: center;
    color: #202020;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
    
}
.package-list li{
    color: #141414;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 10px;
    padding-left: 30px;
    text-align: start;
    position: relative;


}
.package-list li:after {
    content: "";
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M14.2507 8.98735C13.7507 11.4874 11.8657 13.8414 9.22071 14.3674C7.9307 14.6243 6.59252 14.4676 5.39672 13.9197C4.20091 13.3719 3.20843 12.4607 2.56061 11.3159C1.91278 10.1712 1.64263 8.85124 1.78862 7.54402C1.93461 6.23681 2.4893 5.00898 3.37371 4.03535C5.18771 2.03735 8.25071 1.48735 10.7507 2.48735" stroke="%230FB680" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.75 7.98828L8.25 10.4883L14.25 3.98828" stroke="%230FB680" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>');
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 700;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 0;
    text-align: center;
    top: 1px;
    line-height: 20px;
}
.package-list.style-1 li:after {
    content: "";
    background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M13.3346 13.3327L2.66797 2.66602M13.3346 2.66602L2.66797 13.3327" stroke="%23FF1A1A" stroke-width="2" stroke-linecap="round"></path></svg>');
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 700;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    left: 0;
    text-align: center;
    top: 1px;
    line-height: 20px;
}
.package-list li:last-child{
    margin-bottom: 0;
}
.policy{
    margin-top:30px;
}
.policy .accordion-item{
    border: 0;
}
.policy .accordion-button{
    display: flex
    ;
        align-items: center;
        line-height: 33px;
        font-size: 22px;
        font-weight: 600;
        color: #202020;
        text-transform: capitalize;
        padding-left: 0;
        padding-right: 0;
    }
.accordion-button:focus {
    box-shadow: unset!important;
}   
.policy .accordion-body{
    padding-top: 0;

}
.policy .accordion-body ul{
    padding-left: 22px!important;
}
.policy .accordion-body ul li{
    list-style-type: disc;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #515151;
}
.seprator-gradend.style-1{
    margin: 10px 0;
}
.more_content h6{
    color: #202020;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0 10px;
}
.more_content p{
    line-height: 21px;
    color: #515151;
    font-size: 14px;
    font-weight: 400;

    
}
.people_review_box{
    padding: 20px;
    width: 100%;
    min-height: 100px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;

}
.review_media_1 img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
}
.review_content_1 h6{
    font-size: clamp(15px, 1.4vw, 18px);
    font-weight: 600;
    line-height: 21px;
    color: #3c3b3b;
    margin-bottom: 0;

}
.review_content_1 small{
    color: #656565;
    font-size: clamp(12px, 1.1vw, 14px);

}
.review_header{
    display: flex;
    
}
.ReviewCard_rightSection___2gjc {
    color: #19ad6f;
    font-size: clamp(14px, 1.17vw, 15px);
    margin-left: 5px;
    display: flex;
    align-items: center;
    gap: 2px;

}
.review_body{
    margin-top: 13px;
}
.review_body span{
    font-size: clamp(14px, 1.17vw, 15px);
    line-height: 23px;
    color: #8e8e8e;
}
.review_body a{
    font-weight: 500;
    color: #202020;
    margin-right: 4px;

}
.review_body p{
    font-size: clamp(14px, 1.17vw, 15px);
    line-height: 1.4;
    color: #515151;
    margin-bottom: 10px;

}
.custom-gallery{
    display: grid    ;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    
}
.custom-gallery img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 0!important;
}
.related{
    display: flex
    ;
        align-items: center;
        line-height: 33px;
        font-size: 22px;
        font-weight: 600;
        color: #202020;
        text-transform: capitalize;

}
.progress_review h2{
    color: #202020;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;

}
.progress_review h3{
    color: #19ad6f;
    font-size: 50px;
    font-weight: 700;
    line-height: 75px;
    margin-top: -30px;
    text-align: center;

}
.progress_review a{
    display: flex ;
        align-items: center;
        color: #19ad6f;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        -webkit-text-decoration-line: underline;
        text-decoration-line: underline;
        text-decoration: underline;
        text-align: center;
}
.all_progress{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid var(--border-color);
    padding-right: 10px;
}
.progress{
    height: 8px!important;
    width: 70%!important;
    margin-left: 16px;
    margin-right: 16px;
}
.progress-bar{
    height: 8px;
}
.progress-bar.bg-primary{
    background-color: var(--primary)!important;
}
.price_box{
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;


}
.right_content{
    margin-top: 30px;
    position: sticky;
    top: 90px;
}
.price_box h6{
    font-size: 22px;
    font-weight: 600;
    line-height: 38px;
    color: #202020;

}
.price_box small{
    font-size: 12px;
    line-height: 18px;
    color: #8e8e8e;
    margin-left: 8px;

}
.ProductPricing_rating__1NjlU {
    display: flex
;
    gap: 5px;
    align-items: center;
    cursor: pointer;
}
.price_box del{
    font-size: 18px;
    line-height: 27px;
    color: #515151;
    text-decoration: line-through;

}
.legal_box small{
    color: #515151;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    text-transform: capitalize;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0;

}
.LeadForm_priceWrapper__sm4T9 {
    display: flex
;
    flex-direction: row;
    gap: 0;
    align-items: center;
    margin-top: 5px;
}
.LeadForm_actualPrice__TcFIb {
    display: flex
;
    align-items: center;
    color: #202020;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    margin-right: 5px;
}
.LeadForm_strikeThroughPrice__OhcvW {
    display: flex
;
    align-items: center;
    color: #515151;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    -webkit-text-decoration-line: line-through;
    text-decoration-line: line-through;
    margin-right: 5px;
}
.LeadForm_savePrice__y3MK8 {
    display: flex
;
    align-items: center;
    padding: 5px 2px;
    color: #0b822a;
    font-size: 9px;
    font-weight: 600;
    line-height: 14px;
    text-transform: capitalize;
    background: linear-gradient(90deg, rgba(11, 130, 42, .11) 3.64%, rgba(11, 130, 42, .1));
    gap: 3px;
    margin-left: -.5px;
    margin-right: -.5px;
}
.form-control-lg{
 
    align-items: center;
    width: 100%;
    height: 51px !important;
    border-radius: 5px !important;
    border: 1px solid #e0e0e0;
    padding: 17px 15px 17px !important;
    margin: 2px 0 11px !important;
    font-size: 15px !important;
    font-weight: 500;
    color: #515151 !important;
    font-family: Poppins;
    background: transparent;
    z-index: 1;

}
.css-13cymwt-control{
    height: 51px;
}
.profile_media img{
    width: calc(0.02* 100vw + 100px);
    height: calc(0.02* 100vw + 100px);
    object-fit: cover;
    border-radius: 50%;
}
.full-name{
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;

}
.profile_wrapper{
    border: 1px solid var(--border-color);
    border-radius: var(--radius-sm);
    padding: 40px 0px;
    padding-bottom: 0!important;
}
.profile_media{
   display: flex;
   justify-content: center;
}
.profile_content{
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   margin-bottom: 20px;
}
.profile_content p{
    color: #8e8e8e;
    font-size: 16px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    width: calc(100% - 40px);
    margin: 0;
}
.profile_tabs ul li{
   
    padding: 20px 16px 20px 16px;
    border-bottom: 1px solid var(--border-color);
}
.text-light-1{
    color: #676767!important;
}
.profile_tabs ul li:first-child{
    border-top: 1px solid var(--border-color);
}
.profile_tabs ul li:last-child{
    border-bottom: 0;
}
.card{
    background-color: var(--white)!important;
    border: 0!important;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 5px rgba(216,216,216,0.25);
    overflow: hidden;
    margin-bottom: 30px;
}
.card-header{
    background-color: var(--white)!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-header h4{
    margin-bottom: 0;
    margin-left: 20px;
    font-weight: 500;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #505050;

}
.profile_details svg{
    font-size: 16px;
    color: #9c9c9c;
    margin-right: 8px;
}
.profile_details h6{
    font-size: 1rem;
    color: #505050;
    font-weight: 400;
    margin-bottom: 0;
}
.profile_details span{
    color: #bfbfbf;
    font-size: 15px;
    display: flex
    ;
    align-items: center;
    margin-bottom: 0;
    display: block;

}
.profile_details{
    margin-bottom: 20px;
}
.container.style-1{
    max-width:1320px!important;
}
.card-body{
    margin-left: 13px;
}
.card-header h2{
    font-size: 18px;
}
.booking h6{
    font-weight: 500;
    font-size: calc(0.006* 100vw + 18px);

}
.booking {
    display: flex;
    align-items: center;
}
.wallet-main{
   
    padding: 0 20px;
    margin-bottom: 20px;
    display: flex
;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    border: 1px solid var(--border-color);
}
.wallet-media{
    width: calc(0.02* 100vw + 54px);
    height: calc(0.02* 100vw + 54px);
    border-radius: 50%;
    background: #eff8ff;
    padding: calc(0.002* 100vw + 13px);
    margin: 10px 10px 10px 0;

}
.badge-primary{
    color: #f47625 !important;
    height: 25px;
    line-height: 25px !important;
    background: rgba(244, 118, 37, 0.1);
    padding: 0 15px !important;
    border-radius: 3px !important;
    cursor: pointer;
    font-weight: 400 !important;
}
.passengers span{
    color: #8e8e8e;
    font-size: 14px;
    font-weight: 500;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;

}
.passengers .pass_box{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.Offcanvas{
    width: 700px!important;
}
.faq-box{
    display: flex
;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    background: #fff;
    padding: 20px;
   

}
.faq-box h6{
    color: #202020;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}
.offcanvas{
    width: 700px!important;
}
.profile_media_1{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(180deg, #ffba0a 0%, #f47625 100%);
    display: flex
;
    justify-content: center;
    align-items: center;

}
.profile_media_1 span{
    color: #fff;
    font-size: 15px;
    font-weight: 600;

}
.pass{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--border-color);
}
.pass_content{
    margin-left: 16px;
}
.pass .pass_content h6{
    margin-bottom: 10px;
}
.email-box{
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #e0e0e0;

}
.vaction_tour{
    padding: 10px 15px;
    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    margin-top: 20px;
}
.font-w400{
    font-weight: 400!important;
}
.fs-10{
    font-size: 10px!important;

}
.text-small{
    color: #515151!important;
}
.vaction_icon,
.vaction_icon .style-1{
    display: flex
    ;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, #f37022, #fcaf17);
        width: 27px;
        border-radius: 50%;
        height: 27px;
        z-index: 2;
        color: #fff;
        margin-right: 8px;
}
.th-lf-cps-divider {
    width: 100%;
    height: 1px;
    margin: 12px 0;
    background: linear-gradient(90deg, hsla(0, 0%, 87.8%, 0), #e0e0e0 50.27%, hsla(0, 0%, 87.8%, 0));
}
.tour_slected label{
    cursor: pointer;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #e0e0e0;
    transition: all .2s ease-in-out;
    width: 100%;

}
.vaction_icon.style-1{
    width: 40px;
    height: 40px;
}
.tour_slected .form-check-input{
    position: absolute;
    right: 3px;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%!important;
}
.form-check-input:focus{
    box-shadow: unset!important;
}
.vaction-title{
    display: flex;
        align-items: "centre";
        color: #202020;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 16px;
    
}
.date_slected label{
    cursor: pointer;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #e0e0e0;
    transition: all .2s ease-in-out;
    width: 100%;
    font-size: 13px;
    line-height: 20px;
    color: #202020;

}
.date_slected .form-check-input{
    width: 20px;
    height: 20px;
    border-radius: 50%!important;
    margin-right: 14px;
}
.date_slected{
    margin-bottom: 8px;
}
.thank_box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.c-pointer{
    cursor: pointer!important;
}
.tour_media img{
    cursor: pointer;
    height: 450px;
    object-fit: cover;
}
.custom-offanvas{
    width: 100vw!important;
}
.coustom-dropdown{
    font-weight: 500 !important;
    font-size: 15px !important;
    height: 23px;
    line-height: 0px !important;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap !important;
    text-align: start !important;
    margin-left: 10px !important;
    display: flex !important;
    align-items: center;
}
.image{
    width: 21px;
    height: 21px;
    min-width: 21px;
    border-radius: 50%;
    background: linear-gradient(180deg, #ffba0a 0%, #f47625 100%);
    margin-right: 5px;
  display: inline-block;
  text-align: center;
}
.image_text{
    font-weight: 500;
    font-size: 10px;
    line-height: 21px;
    color: #ffffff;

}
.dubai-tour-image{
    width: 100%;
    height: 220px;
    object-fit: cover;
    
}
.details_media{
    position: relative;
}
.details_media::before{
    position: absolute;
    width: 100%;
    height: 25%;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(180deg, hsla(0, 0%, 43%, 0), rgba(0, 0, 0, .36) 29.51%, rgba(0, 0, 0, .6) 64.58%, rgba(0, 0, 0, .8));

}
.details_title{
    position: absolute;
   
    color: #fff;
    z-index: 1;
    font-size: 12px;
    left: 15px;
  
    bottom: 15px;
    z-index: 2;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;

}
.details_media_1{
    position: relative;
}
.view_more{
    position: absolute;
    right: 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    color: #202020;
    padding: 12px 15px;
    cursor: pointer;
    transition: all .2s ease-in-out;
    bottom: 16px;

}
.custom-offanvas .btn-close{
    margin: 0!important;
    background-image: none;
    position: relative;
    opacity: 1;
}
.custom-offanvas .btn-close:after{
    content: "Back";
    position: absolute;
    color: var(--secondary);
    /* background-image: url(./images/back.svg);
    background-repeat: no-repeat;
    background-size: cover; */
    left: 0;
    top: 0;
    font-weight: 500;
    font-size: 18px;
    opacity: 1!important;
}
.back_btn{
    border: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0px 0px 20px 5px rgba(216, 216, 216, 0.25);
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    margin: 0 0 20px 0px;
    display: inline-block;
    text-align: center;
    cursor: pointer;

}
.back_btn svg{
   color: #505050;
    font-size: 21px;
}
.edit_box .card-header{
    grid-column: 1 / -1;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    font-weight: 500;
    margin-right: -20px;
    margin-left: 0px;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    flex-direction: row;

}
.edit_box .form-label{
    color: #bfbfbf;
    font-size: 15px;
  
}
.edit_box .form-control{
    border: 1px solid #dadada;
    border-radius: 5px;
    width: 100%;
   
    height:34px;
    padding:8px 15px

}
.edit_box .form-control::placeholder{
    font-size: 12px;
}
.btn-light{
    border: none !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    color: #7b7b7b !important;
    padding: 10px 20px 10px 20px !important;
    margin-left: 20px;

}
.edit_box .btn-primary{
    border: none !important;
    font-size: 15px !important;
    border-radius: 5px !important;
   height: auto;
    padding: 10px 20px 10px 20px !important;
    margin-left: 20px;

}




.terms_tabs{
    border: 0!important;
}
.terms_tabs .nav-item{
    flex-grow: 0!important;
    white-space: nowrap;
}   
.terms_tabs .nav-link.active{
  border: 1px solid transparent!important;
  color: var(--primary)!important;
}   

.terms_tabs .nav-link{
padding-top: 10px;
padding-bottom: 10px;
margin-right: 40px;
line-height: 20px !important;
min-width: max-content;
display: flex;
color: #515151;
font-size: 14px;

align-items: center;
}
.terms_tabs .nav-link:hover{
    color: var(--primary);
}
.terms_tabs .nav-link:first-child{
    padding-left: 0;

}
.tabs > .tab-content{
    padding-top: 30px;
}
.tabs h1{
    font-size: 30px;
    font-weight: 700;
    /* line-height: 21px; */
    color: #515151;

}
.tabs small{
    color: #999;
    font-size: 0.9rem;
    margin-bottom: 20px;
    margin-top: 10px;
    display: block;

}
.tabs p{
    font-size: 0.9rem;
    margin-bottom: 20px;
    color: #515151;

}
.text-files{
    font-size:1.17rem;
    margin-bottom: 10px;
    font-weight:700;
    color: #515151;
    margin-bottom: 10px;
    display: block;
}
.tabs .terms_tabs{
    border-bottom: 1px solid var(--border-color)!important;
}
.font-500{
    font-weight: 500;
}
.details_tabs.nav-tabs{
    border-bottom: 1px solid var(--border-color);
    justify-content: center;

}

.details_tabs.nav-tabs .nav-link{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #515151;
    border: 0;
    position: relative;
    margin: 0 0.8rem;

}
.details_tabs.nav-tabs .nav-link.active{
    color: var(--primary);
}
.details_tabs.nav-tabs .nav-link::after {
    content: "";
    position: absolute;
    height: 0.1rem;
    background: var(--primary);
    width: 0;
    left: 50%;
    bottom: 0px;
    border-radius: 5px;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
}
.details_tabs.nav-tabs .nav-link.active::after {
    width: 100%;
}
.custom-offanvas{
    overflow: auto;
}
.custom-offanvas .tab-content,
.custom-offanvas .offcanvas-header{
    max-width: 1080px !important;
    margin-left: auto;
    margin-right: auto;

}
.images_details{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}
.images_details_media img{
    width: 100%;
}
.grid-box{
    transition: all 0.5s;
    cursor: pointer;
}
.grid-box:hover{
    transform: translateY(-10px);
}
.choose_media.active{
    border: 2px solid var(--primary);
    padding: 2px !important;
    border-radius: 10px;
}
.distance:has(.form-check-input:checked) .form-check-label {
    border: 1px solid var(--primary-color, #f37002);
    position: relative;
    background: hsla(23, 91%, 95%, .6);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, .15);
    color:var(--primary);
}
/* .cate:has(.form-check-input:checked) .form-check-label {
    border: 1px solid var(--primary);
    position: relative;
    background: hsla(23, 91%, 95%, .6);
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, .15);
    color:var(--primary);
} */
.form-check-label .separator{
    border: 0 !important;
    box-shadow: unset !important;
    color:#f37002;
}

.cate-1 .form-check-input:checked ~ .form-check-label {
    background-color: red;
}

.cate-1.form-check-input:checked + .form-check-label,
	.form-check-input + .form-check-label{
		
		background-color:var(--rgba-primary-1);
		padding:10px;
		margin:0;
		
	}
    .tour_media-1{
        overflow: hidden;
        border-radius: 10px;
    }

.tour_media-1 img{
   
    transition: all 0.5s;
    cursor: pointer;
    overflow: hidden;
}    

.tour_media-1 img:hover{
    transform: scale(1.1);
   
}
.travel_gallary_title{
    display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1.5;
        color: #515151;
    
}
.inquire_box{
    background-image: url('../assets/images/inquires.jpg');
    background-repeat: no-repeat;
    background-size: cover;
   
    border-radius: 10px;
}
.inquire_box h6{
    color: #fff;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.inquire_box p{
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.btn-white{
    border-radius: 7px !important;
    background-color: #fff !important;
    padding: 12px 24px !important;
    color: var(--primary)!important;
    font-size: 14px!important;
    font-weight: 500!important;
   
}
.inquire_box .sub_box{
    width: 50%;
    padding: 20px;
}
.normal_model .modal-dialog{
    max-width: 500px!important;
}
.normal_model h6{
    color: #202020;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    margin-bottom: 3px;

}
.normal_model span{
    color: #fcaf17;
    font-weight: 600;
    margin-left: 5px;
}
.details_tabs.nav-tabs.style-1{
    justify-content: start!important;
    padding-left: 420px!important;
    position: sticky;
    top: 0;
}
.details_tabs.nav-tabs.style-1 .nav-link{
    font-size: 14px;
    font-weight: 400;
    margin-right: 30px;
}
.details_tabs.nav-tabs.style-1 .nav-link:first-child{
    padding-left: 0;
    padding-right: 0;
}
.scroll{
    border-bottom: none !important;
    box-shadow: -5px 9px 10px hsla(0, 0%, 78%, .25);
    background-color: #fff;

}
.reset_password_box{
background-image: linear-gradient(to right, #ff5e00 0%, #ff5e00 50%, #fff 50%, #fff 100%);
position: relative;
height: 110vh;
}
.reset_password{
    background-color: var(--white);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 30px 50px 40px 50px;
  

}
.reset_wrapper{
display: flex;
justify-content: center;
height: 110vh;
align-items: center;
flex-direction: column;
}

.LeadForm_discountHelpText__AbNHL {
    display: flex
;
    align-items: center;
    color: #515151;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
.reset-pass__heading {
    display: flex
;
    font-size: 25px;
    color: #3a3a3a;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
}
.reset_password Form{
    width:100%;
}
.reset_password .form-label{
    font-size: 15px;
    margin-bottom: 10px;
    color: #3a3a3a;
    font-weight: 500;
}
.reset_password input{
border-radius: 5px !important;
padding: 15px 25px !important;
height: unset !important;
font-size: 0.9rem !important;
padding-right: 56px !important;
font-weight: 500;
}
.btn-outline-white{
    margin: 40px auto 0!important;
    outline: none;
    width: 245px!important;
    background: transparent;
    padding: 10px 20px!important;
    font-weight: 600!important;
    border: 2px solid #ff5e00!important;
    color: #ff5e00!important;
    border-radius: 5px!important;
    transition: 0.2s background-color;
    display: flex!important;
    justify-content: center;

}
.eye{
    position: absolute;
    right: 23px;
    top: 49px;

}
.custom_tabs_content .style-1 .form-control {
    height: 51px;
}
.mission-media{
    position: absolute;
 
    right: -1px;
}
.mission-media img{
    width: 89%;
}
.mission_co_founder{
    position: absolute;
    top: 104px;
    left: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.mission_co_founder h6{
    color: #515151;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: .45px;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 0;
    

}
.mission_co_founder small{
    color: #8e8e8e;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: .36px;
    text-transform: capitalize;

}

.mission_co_founder.style-1{
    left: unset;
    right: -96px;
    top: 74px;
}

.mission_span{
    position: absolute;
    top: 84px;
    left: 194px;
}
.mission_span-1{
    position: absolute;
    top: 60px;
    right: 20px;
    transform: rotate(135deg) rotateY(180deg);
}
.section-padding-1 {
    padding-top: 100px;
}
.text-danger{
    color: #f47625!important;
}
.supoort_box{
    background-color: var(--blue_dark);
    height: 500px;
}
.supoort_box .input-group{
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    margin-top: 10px;
}
.supoort_box .form-control{
height: 3.75rem;
padding: 0 1rem;
border-radius: .5rem;
font-size:1.25rem;
}
.supoort_box .input-group-text{
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
}

.supoort{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    
}

.supoort h2{
    color: #fff;
    font-size: 2.625rem;
    font-weight: 700;
    margin-bottom: 0;

}
.light_media img{
    width:5%;
}
.default_margin{
    margin-top: 30px;
    margin-bottom: 20px;
}
.header_search.model_search{
    max-width:unset;
    width: 100%;
}
.Product_Type h6{
    color: #202020;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-top: 10px;
margin-bottom: 0;
}
.product_btn{
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
}
.product_btn span{

    border: 1px solid #e0e0e0;
    background: #fff;
    display: flex
;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    transition: all .2s ease-in-out;
    width: -moz-fit-content;
    width: fit-content;
    min-height: 37px;
    border-radius: 50px;
    min-width: 70px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #515151;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}
.SearchContentSection_horizantalSeperator__taBm5{
    background: #e0e0e0;
    width: 100%;
    height: .5px;
    display: flex;

}
.modal-footer a{
    color: #202020;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline;
    position: absolute;
    left: 15px;
    opacity: 1;
    transition: all .1s ease-in-out;
}
.modal-footer .btn{
    padding: 9px 18px!important;
    justify-content: center;
    align-items: center;
    height: 41px!important;
    border-radius: 7px;
    background: var(--primary);
   
    display: flex;
    margin-right: 15px;
    transition: all .3s ease-in-out;
    opacity: 1;
    gap: 10px;
    margin-left: auto;

}
.Itinerary_summarisedItinerary___bike {
    padding: 10px 0 15px;
    border: 1px solid #e0e0e0;
    border-radius: 7px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
    margin-bottom: 20px
}
.th-summarised-itinerary {
    font-family: Poppins;
}
.th-summarised-itinerary .summary-head {
    margin: 10px 0;
    padding: 0 20px;
}
.th-summarised-itinerary .summary-head .top-section {
    justify-content: space-between;
    align-content: center;
    display: flex
    ;
        flex-wrap: wrap;
    
}
.th-summarised-itinerary .summary-head .bottom-section {
    gap: 10px;
    margin: 10px 0 20px;
    font-weight: 500;
    line-height: 17px;
    color: #515151;
    align-items: center;
}
.th-summarised-itinerary .summary-head .bottom-section, .th-summarised-itinerary .summary-head .top-section {
    display: flex
;
    flex-wrap: wrap;
}
.th-summarised-itinerary .summary-head .bottom-section .event-details {
    display: flex
;
    gap: 10px;
    font-size: 14px;
    align-items: center;
}
.smm-itinerary-day {
    margin-top: 10px;
}
.smm-itinerary-day .smm-day-header {
    background: #f4f6f9;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    color: #202020;
    display: flex
;
    justify-content: space-between;
}
.smm-itinerary-day .th-iti-smm-day-content {
    padding: 15px 20px 5px;
}
.smm-day-event:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.th-iti-smm-header-right-section {
    display: flex
;
    align-items: center;
    gap: 7.5px;
}
.smm-day-event {
    display: flex
;
    gap: 15px;
}
.th-summarised-itinerary .summary-head .trip-summary {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: #202020;
}
.smm-itinerary-day .th-iti-smm-day-content {
    padding: 15px 20px 5px;
}
.smm-day-event:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}
.th-smm-event-icon {
    display: flex
;
    padding-top: 1px;
}
.smm-day-event .right-section {
    display: flex
    ;
        gap: 3px;
        flex-direction: column;
        width: 100%;
        color: #202020;
}
.smm-day-event .right-section .th-is-event-title {
    display: flex
;
    padding-top: 1px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
}
.smm-day-event .right-section .th-is-single-event {
    margin-left: 7px;
    font-size: 13px;
    font-weight: 400;
}
.th-lde-wrapper {
    display: flex
;
    border-radius: 5px;
    border: 1px solid #ffebc4;
    background: rgba(252, 175, 23, .1);
    width: 100%;
    height: 49px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 10px 0 0;
    color: #e89b03;
    font-size: 13px;
    font-weight: 400;
}
.th-lde-wrapper .th-lde-label {
    margin-right: 2.5px;
    margin-left: 10px;
}
.th-iti-smm-header-event-count-box {
    display: flex
;
    align-items: center;
    gap: 7.5px;
    color: #515151;
    font-size: 11px;
}
.th-iti-smm-header-rs-divider {
    min-width: 1px;
    max-width: 1px;
    width: 1px;
    min-height: 17px;
    max-height: 17px;
    height: 17px;
    display: flex
;
    background: #e0e0e0;
}
.th-is-multi-event-box {
    padding-left: 15px !important;
    padding-top: 2px;
}
.th-is-multi-event {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 4px !important;
    list-style-type: disc;
}
.th-day-meal-wrapper {
    background: #f4f4f4;
    border-radius: 5px;
    padding: 15px 15px 16px;
    display: flex
;
    flex-direction: column;
    gap: 10px;
}
.th-day-meal-wrapper .th-meal-inclusion-wrapper {
    display: flex
;
    gap: 10px;
    flex-wrap: wrap;
}
.th-day-summarised-itineray-meals .th-meal-inclusion-wrapper .th-meal-inclusion-item {
    gap: 5px;
    display: flex
    ;
       
        align-items: center;
}
.th-day-summarised-itineray-meals .th-meal-inclusion-wrapper .th-meal-inclusion-item .th-meal-inclusion-text {
    font-size: 11px;
    line-height: 16.5px;
}

.card.fw-horizontal {
    text-align: left;
    padding: 1.2rem;
    flex-direction: row;
    border-radius: .75rem;
    box-shadow: 0 6px 19px rgba(39, 49, 58, 0.1);
    height: 100%;
    align-items: center;
    word-wrap: break-word;
    background-clip: border-box;
    margin: 0;
    
}
.card.fw-horizontal .card-title {
    margin-bottom: .25rem;
    color:var(--blue_dark);
    font-weight: 600;
    font-size: 1.125rem !important;

}
.support-container{
    max-width: 1320px;
    margin: auto;
    margin-top:-48px;
}

.line-clamp-3{
    margin-bottom: 0;
    color: #264966;
    font-size: 15px;
    -webkit-line-clamp: 3;
    font-weight: 500;
    line-height: 1.8;


   
}
.fs-18{
    font-size: 1.125rem!important;
}
.card.fw-horizontal .fw-category-icon {
    width: 4rem;
}
.card.fw-horizontal .card-body {
    padding: 0 0 0 .75rem;
    margin: 0;
}
.mb-md-16 {
    margin-bottom: 1rem !important;
}
.fw-section-heading h2{
    font-size: 1.5rem;
    color: #264966;
    font-weight: 700;
    font-family:  Source Sans Pro;
}
.fw-section-heading a{
    color:#2C5CC5;
}
.support_body{
    font-family: Source Sans Pro;
    background-color: #f5f7f9;
}
.fw-kbase-card{
    padding: 1.5rem;
    text-align: center;
    word-wrap: break-word;
}
.fw-kbase-card .card-body {
    padding: .625rem 0 0;
}
.icon-category img{
    width: 33%;
}
.fw-kbase-card .card-body .line {
    font-weight: 600;
    color: #264966;
    margin-bottom: 1rem;
}
.fw-kbase-card .card-body .line-clamp-2{
display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
margin-block-start: 1em;
margin-block-end: 1em;
margin-inline-start: 0px;
margin-inline-end: 0px;
unicode-bidi: isolate;
margin-bottom: 0;

}
.pb-44 {
    padding-bottom: 2.75rem !important;
}
.pt-60 {
    padding-top: 3.75rem !important;
}
.support-title{
    font-weight: 600;
    font-size: 20px;
    color: #264966;
}
.fw-article-info {
    padding: 2rem 1rem;
    border-bottom: 1px solid #EBEFF3;
}
.fw-article-info .fw-meta-info {
    font-size: .825rem;
    color: #264966;
}
.pe-8 {
    padding-right: 0.5rem !important;
}
.line-clamp-2, .line-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.semi-bold{
    font-weight: 600;
}
.fw-articles .fw-article-info:nth-child(odd) {
    border-right: 1px solid #EBEFF3;
}
.fw-articles {
    padding: 0 1rem;
}

@media (min-width: 768px) {
.mt-md-44 {
    margin-top: 2.75rem !important;
}
}
@media screen and (max-width: 1600px) {
    .seprator{
        width:100vw;
        left: -89px;
    }

}
@media screen and (max-width: 1440px) {
    .seprator{
        width:100vw;
        left: -34px;
    }

}
@media screen and (max-width: 1400px) {
    .seprator{
        width:100vw;
        left: -12px;
    }
}
@media screen and (max-width: 1024px) {
    .container{
        max-width: 1080px !important;
        width: 84.375vw !important;
    
    }
    .seprator{
        width: 100vw;
        height: 1px;
        background-color: var(--border-color);
        position: relative;
        left: 67px;

    }
    .banner-content{
        height:400px;
    }
}
@media screen and (max-width: 992px) {
    .footer-main{
        height: auto;
    }
    .itinerary-heading h1{
        font-size:21px!important;
    }
    .itinerary-days .accordion-button{
            font-size:12px;
    }
    .custom-gallery{
        grid-template-columns:repeat(5, 1fr);
    }
}
@media screen and (max-width: 768px) {
    .container{
        max-width: 1080px !important;
        width: 89.375vw !important;
    
    }
    .container.style-2{
        max-width: 1267px !important;
        width: 90.375vw !important;
    
    }
    
    .tour_media img{
        height: 224px;
    }
    .dubai-tour-image{
        height:108px;
    }
    .view_more{
        position: absolute;
    right: 4px;
    border-radius: 5px;
    display: flex
;
    align-items: center;
    gap: 5px;
    background-color: #fff;
    color: #202020;
    padding: 7px 9px;
    cursor: pointer;
    transition: all .2sease-in-out;
    bottom: 16px;

    }
    .custom-gallery{
        grid-template-columns:repeat(4, 1fr);
    }
   
    .seprator{
        width: 100vw;
        left: 90px;

    }
    .header_search{
        max-width:213px;
    }
    .swiper-button-prev, .swiper-button-next{
        top: 25% !important;
    
    }
}
@media screen and (max-width: 600px) {
    .container{
        max-width: 1080px !important;
        width: 89.375vw !important;
    
    }
    .profile_wrapper{
        margin-bottom: 16px;
    }
    .custom-gallery{
        grid-template-columns: repeat(3, 1fr);
    
    }
    .supoort h2{
        font-size:1.5rem;
    }
    .supoort_box .input-group{
        max-width: 500px;
    }
    .seprator-gradend{
        background: none;
    }
    .package-list.style-1{
        border-right: 0;
    }
    .all_progress{
        border-right: 0;
    }
    .inquire_box .sub_box {
        width:100%;
    }
    .tour_content_left h1{
        font-size: 25px;
        line-height: normal;
    }

    .supoort_box .form-control{
        height: 2.75rem;
        padding: 0 1rem;
        border-radius: .5rem;
        font-size: 1rem;
    
    }
    .supoort_box {
        height: 250px;
    }
    .supoort{
        height: 250px;
    }

    .seprator{
        width: 100vw;
        left: 90px;

    }
    .header_search{
        max-width:213px;
    }
}
@media screen and (max-width: 575px) {
    .reset-pass__heading {
        font-size: 18px!important;
    }
    .reset_password{
        padding: 20px 16px 30px 16px;
    
    }
    .section-span, .section-title{
        font-size: 30px;
    }
    .about_title{
        font-size:15px;
    }
    .about_heding{
        font-size: 30px!important;
    }
    .section-span, .section-title{
        font-size:30px;
    }
    .banner-content{
        height:428px!important;
    }
    .tour_media img{
        width: 100%!important;
    }
    .custom-gallery img{
        width: 100%;
        height: 100px;
    }
    .policy .accordion-button{
        font-size: 18px;
    }
    .seprator-gradend .style-1{
        display: none;
    }
}
@media screen and (max-width: 400px) {
    .container{
        max-width: 1080px !important;
        width: 100.375vw !important;
        margin: 0;
       
    
    }
   
    .seprator-gradend .style-1{
        display: none;
    }
    .container.style-2{
        margin: 0!important;
    }
    .seprator{
        width: 100vw;
        left: 141px;

    }
    .header-top h1{
        font-size: 11px;
    }
    .logo img{
        width: 100px!important;
        margin-right:8px;
    }
    .heading{
        font-size: 16px;
    }
    .HomePageBody_verticalSeperator__e_C7b{
        display: none;
    }
    .activites_tab {
        margin-top: 20px;
    }
    .activites_tab .nav-tabs{
        width: 100%;
        max-width: unset;
    }
    .activites_tab .nav-tabs .nav-link{
        width: 158px;
    }
    .header_search .form-control{
        display: none;
    }
    .header_search{
        max-width: 213px;
        width: 10% !important;
        border: 0;
        padding: 0;
        box-shadow: unset;
    }
    .trade_name{
        right: -22px;
    }
    .main-swiper .swiper-button-next,
    .main-swiper .swiper-button-prev{
        display: none;
    }
    .sub-swiper .swiper-button-next,
    .sub-swiper .swiper-button-prev{
        display: inline-flex        ;
                top: 50% !important;
            
    }
    .main-swiper .swiper-pagination-bullets{
        display: none;
    }
    .sub-swiper .swiper-pagination-bullets{
        display: inline-flex;
        left: 50%!important;
    }
    .modal-header .btn-close{
        top: 0px;
        right: 7px;
    
    }
    .login-model .col-lg-7{
        max-width: 100%;
    }
    .login_model{
        display: none;
    }
    .login-model .col-lg-5{
        width: 100%;
    }
  
    .row.coustom-row{
        display: contents;
    }
    .css-13cymwt-control{
        height: 45px;
    }
    .btn-login small{
        margin-bottom: 16px;
        display: block;

    }

  
}

@media screen and (max-width: 1600px) {
    .seprator{
        width:100vw;
        left: -89px;
    }
    .details_tabs.nav-tabs.style-1{
        padding-left: 220px!important;
    }

}
@media screen and (max-width: 1445px) {
    .details_tabs.nav-tabs.style-1{
        padding-left: 220px!important;
    }
}

@media screen and (max-width: 1440px) {
    .seprator{
        width:100vw;
        left: -34px;
    }
    .details_tabs.nav-tabs.style-1 {
        padding-left: 180px !important;
    }
}


@media screen and (max-width: 1400px) {
    .seprator{
        width:100vw;
        left: -12px;
    }
    .details_tabs.nav-tabs.style-1 {
        padding-left: 159px !important;
    }
}
@media screen and (max-width: 1300px) {
    .details_tabs.nav-tabs.style-1 {
        padding-left: 111px !important;
    }
}

@media screen and (max-width: 1200px) {
    .details_tabs.nav-tabs.style-1 {
        padding-left: 60px !important;
    }


}
@media screen and (max-width: 1024px) {
    .container{
        max-width: 1080px !important;
        width: 84.375vw !important;
    
    }
    .founder-box{
        grid-template-columns:repeat(4,1fr);
        gap: 42px;
    }
    .gallary_box img{
        height:134px;
    }
    .ThrillophiliaExpeditionSection_thrilloExpeditionContainer__FcRMv .ThrillophiliaExpeditionSection_expeditionItemContainer__2_Iw0 .ThrillophiliaExpeditionSection_imagePoster__Mm10g{
        width:230px;
    }
    .about_heding{
        font-size: 35px!important;
    }
    .section-title{
        
    }
    .LeadForm_priceWrapper__sm4T9{
        flex-wrap: wrap;
    }
    .details_tabs.nav-tabs.style-1 {
        padding-left: 79px !important;
    }
    .support-container{
        max-width: 960px!important;
    }
    .seprator{
        width: 100vw;
        height: 1px;
        background-color: var(--border-color);
        position: relative;
        left: 67px;

    }
}
@media screen and (max-width: 992px) {
    .footer-main{
        height: auto;
    }
    .support-container{
        max-width: 720px!important;
    }
}
@media screen and (max-width: 768px) {
    .container{
        max-width: 1080px !important;
        width: 89.375vw !important;
    
    }
    .sub-section-title{
        font-size: 23px;
    }
    .gallary_box img{
        height:104px;
    }
    .thumb_content h2{
        font-size:23px;
    }
    .founder-box{
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    
    }
    .hiring-titk h1{
        font-size: 30px;
    }
    .details_tabs.nav-tabs.style-1 {
        padding-left: 60px !important;
    }
    .lotti-box{
        transform:scale(0.8);
    }
    .banner-content{
        height:553px;
    }
    .mission-media{
        position: unset;
        text-align: center;
    }
    .c-padding{
        padding-bottom: 0;
    }
    .mission-media img{
        width: 50%;
    }
    .mission_span-1{
        right:127px;
    }
    .mission_co_founder.style-1{
        right:0;
    }
    .section-span,
    .section-title{
        font-size:40px;
    }

    .support-container{
        max-width: 720px!important;
    }
   
    .seprator{
        width: 100vw;
        left: 90px;

    }
    .header_search{
        max-width:213px;
    }
    .swiper-button-prev, .swiper-button-next{
        top: 25% !important;
    
    }
}
@media screen and (max-width: 600px) {
    .container{
        max-width: 1080px !important;
        width: 89.375vw !important;
    
    }
    .city_content h2{
        font-size: 18px;
    }
    .mission_span{
        left:131px;
    }
    .mission_co_founder{
        left: -10px;
    }
    .details_tabs.nav-tabs.style-1 {
        padding-left: 48px !important;
    }
    .support-container{
        max-width: 540px!important;
    }
    .card.fw-horizontal{
        margin-bottom: 12px !important;
        height: auto !important;
    
    }
    .support-container.h-100{
        height: auto!important;
    }
    .mb-24 {
        margin-bottom: 1.5rem !important;
    }
    .mt-28 {
        margin-top: 1.75rem !important;
    }
    .icon-category img{
        width: 18%!important;
    
    }
    .fw-kbase-car{
        height: auto!important;
    }
    .seprator{
        width: 100vw;
        left: 90px;

    }
    .header_search{
        max-width:213px;
    }
}
@media screen and (max-width: 575px) {
    .container{
        max-width: 1080px !important;
        width: 92.375vw !important;
    
    }
    .tabs h1{
        font-size: 27px;
    }
    .choose_media img{
        width: 86px;
        height: 86px;
        border-radius: 6px;
        object-fit: cover;
    }
    .details_tabs.nav-tabs.style-1{
        padding-left: 14px !important;
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow-y: scroll;
        scrollbar-width: none;
    
    }
    .choose_media:before{
        width: 86px;
    }
    .supoort_box .input-group{
        max-width: 318px!important;
    }
    .footer-main .border-right{
        border-right:0
    }
    .logo_footer img{
        width: 35%!important;
    }
    .logo_footer:after, .logo_footer::before{
        width: 20%;
    }
    .logo_footer:before{
        left: 24px;
    }
    .social_box ul li a {
        margin-right: 10px;
    }
    .seprator{
        width: 100vw;
        left: 141px;

    }
    .header-top h1{
        font-size: 11px;
    }
    .extra-footer-content b{
        font-size:14px;
    }
    .extra-footer-content p{
        font-size: 13px;
    }

    .logo img{
        width: 87px!important;
        margin-right:8px;
    }
    .heading{
        font-size: 16px;
    }
    .coustom-dropdown {
        font-size: 13px !important;
        margin-left: 5px !important;
       
    }
    .header_search{
        overflow: visible;
    }
    .footer-section{
        margin-top:55px!important;
    }
    .details_tabs.nav-tabs{
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow-y: scroll;
        justify-content: start;
    }
  
}




table {
    width: 100%;
    margin: auto;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  th, td {
    padding: 15px 20px;
    border: 1px solid #ddd;
    text-align: left;
  }

  th {
    background-color: #f8f8f8;
    font-weight: bold;
  }

  caption {
    font-size: 1.5em;
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }

  .sh-4{
   font-size: 20px;
   margin-bottom: 10px;
   display: block;
   font-style: italic;
  }

  
    .card {
      background: #ffffff;
      border-radius: 12px;
      box-shadow: 0 4px 10px rgba(0,0,0,0.1);
      padding: 30px;
      margin-top: 20px;
    }

    h1 {
      text-align: center;
      color: #3e3e3e;
    }

    h2 {
      color: #444;
      border-bottom: 2px solid #e0e0e0;
      padding-bottom: 5px;
      margin-top: 30px;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    /* ul li::before {
      content: "✅ ";
      color: green;
    } */

    .contact {
      margin-top: 20px;
    }

    .note {
      margin-top: 20px;
      padding: 10px;
      background-color: #fff7d1;
      border-left: 4px solid #ffc107;
      border-radius: 4px;
    }

            