.header-top{
    display: flex;
    flex-direction: row;
    background: linear-gradient(93deg, #ff7b26, #ec048c);
    width: 100%;
    height: 38px;
    align-items: center;
    justify-content: center;
    position: relative;
    
}
.header-top h1{
    display: flex    ;
    color: var(--white);
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    justify-content: center;
    gap: 5px;
    margin-bottom: 0;
    
}
.header-top span{
    font-weight: 500;
    
}
.header_compont{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_search{
    max-width: 300px;
    border: 1px solid var(--border-color);
    border-radius: var(--radius-xl);
    overflow: hidden;
    box-shadow: var(--box-shadow-default);
    padding: 10px 10px 10px 20px;
    height: 40px;
    
}
.header_search .input-group-text{
    background-color: var(--white);
    border-right: 0;
    border: 0;
    padding: 0;
}
.header_search .form-control{
    border-left: 0;
    padding-left: 0;
    border: 0;
    color: var(--text-dark-light);
    font-size: 14px;
    padding: 0;
    padding-left: 8px;
   
}
.header_search .form-control:focus{
    box-shadow: unset;
}
.haeder_btn{
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    font-size: 14px !important;
    color: #515151 !important; 
    font-weight: 500!important;  
    justify-content:center;
}
.haeder_btn img{
    width: 20%;
    margin-right: 5px;
}
.currency-box{
    padding: 7px;
    margin: 5px 10px;
   line-height: 1;
    border-radius: 5px;
    position: relative;
    font-size: 1rem !important;
    transition: all 0.5s;
    border: 1px solid transparent;
    cursor: pointer;

}
.currency-box:hover{
    border: 1px solid var(--primary);
    
}
.modal-dialog {
    max-width: 900px!important;
}
.form-control{
    border-color: var(--border-color);
}
.form-control:focus{
    border-color: var(--border-color)!important;
    box-shadow: unset!important;
}
.modal-header .btn-close{
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    box-shadow: var(--box-shadow-default);
    border-radius: var(--radius-xl);
    z-index: 1;
    background-color: #fff;
    top: -12px;
}
.login_model{
   background-image: url(../assets/images/login-background.jpg);
   width: 100%;
   height: 100vh;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   position: relative;
   z-index: 1;
   color: var(--white);
}
.login_model:before{
    position: absolute;
    content: "";
    opacity: .75;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(43deg, #000, transparent 50%, rgba(0, 0, 0, .7));
    z-index: 0;
}
.react-tabs__tab-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.react-tabs__tab-list li{
    font-size: 18px;
}
.seprator{
    width: 99vw;
    height: 1px;
    background-color: var(--border-color);
    padding-left: 72px;
    margin-left: -161px;

}
.custom_tabs_content h1{
    margin: 20px 0 15px;
    color: #3c3b3b;
    font-size: clamp(20px, 1.5vw, 26px);
    font-weight: 500;
    line-height: 1.3;
}
.custom_tabs_content .form-control{
    width: 100%;
    outline: none;
    border: 1px solid #e0e0e0;
    height: clamp(45px, 3.5vw, 80px);
    border-radius: 5px;
    padding: 10px 20px;
    color: #515151;

}
.custom_tabs_content .form-control::placeholder{
    font-size: clamp(14px, 1.17vw, 15px);
font-weight: 400;
line-height: 23px;
color: rgb(203, 203, 203);

}
.login-model .modal-dialog{
    max-width: 1000px !important;
}

.custom_tabs .nav-tabs {
    width: 97%;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-around;
    padding:20px 20px 0px;
}

.custom_tabs .nav-tabs .nav-link {
    border: 0;
    position: relative;
    font-weight: 400;
    color: #969ba0;
    text-decoration: none;
    padding: 10px 15px;
    font-size: clamp(18px,1.4vw,18px);
}

.custom_tabs .nav-tabs .nav-link.active {
    background: transparent;
    color: var(--primary);
}

.custom_tabs .nav-tabs .nav-link::after {
    content: "";
    position: absolute;
    height: 0.1rem;
    background: var(--primary);
    width: 0;
    left: 50%;
    bottom: 0px;
    border-radius: 5px;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
}

.custom_tabs .nav-tabs .nav-link:hover::after,
.custom_tabs .nav-tabs .nav-link.active::after {
    width: 100%;
}
.login_text{
    width: 80%;
    height: 100%;
    position: absolute;
    display: flex
;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
}

.login_text h2{
    line-height: 1.2;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -2px;
    margin-bottom: 15px;

}

.animation_slider{
    display: flex;
    animation: skew_animation 4s linear infinite
}
@keyframes skew_animation {
    0% {
        transform: translateX(0);
        opacity: .1;
    }
    50% {
        transform: translateX(540px);
        opacity: 1;
    }
    100% {
        transform: translateX(1080px);
        opacity: .1;
    }
}
.dropdown-menu{
    display: flex;
        border-radius: 5px !important;
        background: #fff;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0px 0px 20px 5px rgba(216, 216, 216, 0.25);
        border: 1px solid #f2f2f2 !important;
    
    }

.dropdown-item {
    display: flex !important    ;
    flex-direction: row;
    padding: 10px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    align-items: center;
    grid-gap: 10px !important;
    gap: 10px !important;
    transition: all 0.2s ease;
    }

.header_text{
    color: #676767;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.login-model .col-lg-7{
    max-width: 55%;
    flex-shrink: 0;
    border-radius: 5px 0px 0px 5px;
    overflow: hidden;

}
.login-model .col-lg-5{
    width: 45%;
}
.btn-login{
    width: 100% !important;
    color: rgb(255, 255, 255);
    font-size: clamp(14px, 1.17vw, 15px) !important;
    padding: 10px;
    margin: 10px 0px;
    border: 0;
    font-weight: 400!important;


}
.css-1u9des2-indicatorSeparator{
    display: none;
}
.btn-close:hover{
    opacity:1!important;
}
.css-13cymwt-control,
.css-t3ipsp-control{
    height: clamp(45px, 3.5vw, 80px);
}
@media screen and (max-width: 600px) {
    .logo img{
        width: 106px!important;
    }
}