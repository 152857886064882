.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.form-check-input {
  transition: border 0.2s ease; /* Smooth transition for border */
}

.form-check-input:hover {
  border-color: red !important; /* Red border on hover */
}

.form-check-input:checked {
  background-color: green;
  border-color: green;
}








.search-container {
  position: relative;
  width: 300px;
}

.search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.suggestions-list {
  
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background: #f0f0f0;
}

.loading-text {
  color: #666;
  font-size: 14px;
  margin-top: 5px;
}
.link-wrap {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.page-link {
  width: fit-content;
  border: 1px solid #e7b693 !important;
  margin: 5px;
  transition: 0.3sease -in-out;
  border-radius: 5px;

}
.page-link {
  position: relative !important;
  display: block !important;
  padding: .5rem .75rem !important;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;

}
.page-link a {
  color: #f37002;
  transition: 0.3sease-in-out;
  text-transform: capitalize;
}
.page-link:hover{
  background-color:#f37002!important;

}

.page-link:hover a{
  color:#fff!important;

}















.row {
  display: -ms-flexbox;
  display: flex
;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}



.row {
  display: -ms-flexbox;
  display: flex
;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.contact-shadow {
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: #fff;
  border-radius: 10px;
  display: flex
;
  align-items: center;
}
.contact-info-area {
  background-color: #fff;
  border-radius: 10px;
}
.contact-shadow {
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: #fff;
  border-radius: 10px;
  display: flex
;
  align-items: center;
}

.contact-info-area .title {
  font-size: 24px;
  text-align: center;
  padding: 18px 0px;
  background-color: #f37002;
  color: #fff;
  font-weight: 600;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  

}
.contact-content {
  padding: 0px 20px;
}
#get-in-touch a {
  color: #000;
}
.contact-info-area .contact-info-list li svg{
  padding-right: 5px;
  margin-right: 12px;
  padding-top: 7px;
  color: #f78b3f;
  width: 2rem;
  display: flex
;
  align-items: center;
  justify-content: center;
}
.contact-info-area .contact-info-list li {
  font-size: 16px;
  line-height: 26px;
  display: flex
;
  margin: 10px 0;
  align-items: baseline;
}
.contact-form-content-area {
  padding: 25px;
  border-radius: 15px;
}
.form-group {
  margin-bottom: 1rem;
}
.theme-button {
  border: 1px solid #f46603;
  border-radius: 25px;
  background-color: #f2f9f9;
  padding: 7px 25px !important;
  color: #f46603 !important;
  display: inline-block;
  transition: 0.3s;
  font-size: 14px;
}
.contact-shadow {
  height: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: #fff;
  border-radius: 10px;
  display: flex
;
  align-items: center;
}
ul.social-icon {
  margin: 0;
  padding: 0px 10px;
  margin-bottom: 20px;
  /* display: flex
;
  align-items: center; */
}
.contact-info-area .social-icon li {
  display: inline-block;
  margin-right: 5px;

}
.contact-info-area .social-icon li.facebook {
  background-color: #0069f7;
  color: #fff;
  border-radius: 50%;
}
.contact-info-area .social-icon li a {
  display: block;
  width: 40px;
  height: 40px;
  background-color: transparent;
  color: #fff !important;
  line-height: 40px;
  text-align: center;
}
.contact-info-area .social-icon li.twitter {
  background-color: #00c6f7;
  color: #fff;
  border-radius: 50%;
}
.contact-info-area .social-icon li.linkedin {
  background-color: #0072b1;
  color: #fff;
  border-radius: 50%;
}
.contact-info-area .social-icon li.instagram {
  background-color: #962fbf;
  color: #fff;
  border-radius: 50%;
}
.contact-info-area .social-icon li.tumblr {
  background-color: #34526f;
  color: #fff;
  border-radius: 50%;
}
.contact-info-area .social-icon li.pinterest {
  background-color: rgb(230, 0, 35);
  color: #fff;
  border-radius: 50%;
}
.bg-primary-1{
  background-color:rgb(230, 0, 35, 0.1); ;
}
.description {
  height: 13rem;
  text-align: center;
  border-radius: 5px 44px;
  padding: 27px 15px 15px 15px;
  background: linear-gradient(227deg, rgb(244 102 3) 0%, rgb(244 102 3 / 30%) 100%);
  background-color: #fac38c;
}
.author-meta {
  display: flex
;
  justify-content: space-around;
  align-items: center;
  position: relative;
  bottom: 46px;
  right: 0px;
  left: 44px;
  background-color: rgb(255, 255, 255);
  width: 84%;
  box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px, rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  border-radius: 15px;
  padding: 10px;
}

.description p {
  margin: 0;
  color: #fff;
}


.client-star {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex
;
  align-items: center;
}
.client-star li {
  margin-right: 3px;
  color: #fac38c;
}
.content .city-name {
  color: #644b3a;
  margin-bottom: 2px;
  font-weight: 600;
}
.content .name {
  color:  rgb(230, 0, 35);
  margin-bottom: 2px;
}
.author-meta img {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 50%;
}




.btn-floating:hover img {
  margin-bottom: -3px
}

.btn-floating {
    position: fixed;
    right: 25px;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    border: 0;
    z-index: 9999;
    color: white;
    transition: .2s;
}

.btn-floating:hover {
    width: auto;
    padding: 0 20px;
    cursor: pointer;
}

.btn-floating span {
    font-size: 16px;
    margin-left: 5px;
    transition: .2s;
    line-height: 0px;
    display: none;
}

.btn-floating:hover span {
    display: inline-block;
}

/* Phone */
.btn-floating.phone {
    bottom: 125px;
    background-color: #760f10;
}

.btn-floating.phone:hover {
    background-color: #c03421;
}
.carrior_main{
  position: relative;

}
.carrior_main:after{
  background-color: #000;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.7;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
}
.left_carrior_content{
  color: #fff;
  position: relative;
  z-index: 1;
  
}
.main_yatra_banner{
  min-height: 500px;
  display: flex;
 
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}
    .section__advertise-intro-form {
        position: absolute;
        right: 0;
        top: 5%;
        width: 360px;
    }
    .section__advertise-intro-form__inputs {
      background-color: #fff;
      padding: 40px;
  }
  .section__advertise-intro-form__inputs-input-wrapper {
    position: relative;
}
.section__advertise-intro-form__inputs-input-wrapper .section__advertise-intro-form__inputs-input.advertisement-form-input {
  font-size: 20px;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  background: #fff;
  padding: 10px;
  padding-left: 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #d9d9d9;
  transition: 0.2s all;
  height: auto;
}
.section__advertise-intro-form__inputs-input-wrapper .section__advertise-intro-form__inputs-input.advertisement-form-input::placeholder {
  font-size: 15px;
}
.section__advertise-intro-form__inputs-input-wrapper .section__advertise-intro-form__inputs-input.advertisement-form-input:focus-visible{
 outline: 0;
}
.section__advertise-intro-form__inputs label {
  position: absolute;
  font-size: 15px;
  cursor: text;
  top: 0;
  left: 0;
  -webkit-transition: color 0.2sease-out, -webkit-transform 0.2sease-out;
  transition: color 0.2sease-out, -webkit-transform 0.2sease-out;
  transition: transform 0.2sease-out, color 0.2sease-out;
  transition: transform 0.2sease-out, color 0.2sease-out, -webkit-transform 0.2sease-out;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  color: #333333;
}

.section__advertise-intro-form__header {
  color: #fff;
  padding: 20px 40px;
  background-color: rgba(0, 0, 0, 0.8);
  border-left: 5px solid #ff5300;
  text-align: left;
}

.section__advertise-intro-form__inputs select {
  position: absolute;
  font-size: 15px;
  cursor: text;
  top: 0;
  left: 0;
  -webkit-transition: color 0.2sease-out, -webkit-transform 0.2sease-out;
  transition: color 0.2sease-out, -webkit-transform 0.2sease-out;
  transition: transform 0.2sease-out, color 0.2sease-out;
  transition: transform 0.2sease-out, color 0.2sease-out, -webkit-transform 0.2sease-out;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  color: #333333;
}
.section__advertise-intro-form__inputs-input-wrapper .section__advertise-intro-form__inputs-input.advertisement-form-input.advertisement-enquiry-submit-button {
  border: none;
  border-radius: 8px;
  background-image: linear-gradient(to right, #ff7102 0%, #ff0256 100%);
  padding: 15px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-size: 14px;
  margin-top: 16px;
}

.blog_main_content{
  background-color: #383e59;
  border-radius:10px 10px 10px 10px;
  padding: 10px;
  position: relative;
}
.blog_main_content:before,
.blog_main_content:after{
  content: "";
  display: inline-block;
  position: relative;
  height: 7px;
  width: 60px;
  border-top: 2px solid #f46603;
  border-bottom: 2px dashed #f46603;
  transform: skew(-30deg, 0);
}


.blog_main_content h1{
  margin-bottom: 0;
}