.footer-section{
    display: flex
    ;
        flex-direction: column;
        align-items: center;
       
        height: auto;
        background: #2f343c;
        margin-top: 200px;
        padding-bottom: 50px;
    
}
.footer-main{
    background-color: var(--white);
    padding: 10px;
    /* height: 300px; */
    border-radius: var(--radius);
 
    
    margin-top: -100px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, .14);
    padding: 40px;
    box-sizing: border-box;
    margin-bottom: 40px;

}
.footer-main h2{
    display: flex
    ;
        align-items: center;
        font-size: 12px;
        color: #424242;
        font-weight: 600;
        line-height: 18px;
        margin-bottom: 10px;
        letter-spacing: 1.6px;
    
}
.footer_link a{
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #858585;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
    letter-spacing: 1.3px;
    cursor: pointer;
    transition: all .1s linear;
    text-transform: uppercase;

}
.logo_footer{
    position: relative;
    text-align: center;
}
.logo_footer:after,
.logo_footer::before{
    content: "";
    position: absolute;
    background-color: gray;
    top: 50%;
    width: 39%;
    height: 1px;
}
.logo_footer:after{
    right: 30px;
}
.logo_footer:before{
    left: 30px;
}
.icon-box{
    width: 40px;
    height: 40px;
    background-color: var(--white);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 40px;
}
.icon-box svg{
    color: var(--primary);
}
.social_box ul{
    display:flex;
    align-items: center;
    justify-content: center;
}
.social_box ul li a{
    margin-right: 16px;
}
.extra-footer-content{
    color:#b7b7b7;
}

.travel_destination{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 4px;
}
.travel_img{
    position: relative;
    
    overflow: hidden;
    
}
.travel_img img{
    transition: all 0.5s;
   width: 100%;
 
}
.travel_img a{
    margin-bottom: 0;
}
.travel_text{
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    transform: translate(-50% , -50%);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}
.travel_img img:hover{
    transform: scale(1.1);
}